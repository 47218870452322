<template>
  <app-container class="exclude-data">
    <p>
      Protocolo: <b>{{ code }}</b>
    </p>
    <p style="color: #4caf50; font-size: 24px; margin-top: 24px">
      Exclusão de dados completada com sucesso!
    </p>
  </app-container>
</template>

<script>
import AppContainer from '@/components/AppContainer'

export default {
  name: 'ExcludeData',
  components: { AppContainer },
  data() {
    return { code: '' }
  },
  created() {
    this.code = this.$route.params.code
  }
}
</script>

<style lang="sass" scoped></style>
